import * as React from "react"

import '../styles/nav.css'

import { Link } from "gatsby"

const Nav = () => {

    // const active = "home"

    return (
            <>
                <div className="navbar">
                    {/* <Link to="/"            className={`${ active == '/'             ?'active':''} nav-item`}>How I can help</Link> */}
                    {/* <Link to="/sponsorship" className={`${ active == '/sponsorship/' ?'active':''} nav-item`}>Sponsored Content</Link> */}
                    {/* <a href="/downloads"   className={`${ active == '/downloads/'   ?'active':''} nav-item`}>Downloads</a> */}
                    {/* <a href="/connect"     className={`${ active == '/connect/'     ?'active':''} nav-item`}>Connect</a> */}
                </div>
            </>
    )
}

export default Nav