import * as React from "react"

import '../styles/header.css'

const Header = () => {
    return (
            <>
                <h1 className="header-name">Bas Steins</h1>
                    <p className="header-tagline">Helping teams create better software</p>
                    <p>
                        <span>
                        </span>
                    </p>
            </>
    )
}

export default Header