import * as React from "react"
import Img from "gatsby-image"

import '../styles/avatar.css'

const Avatar = ({data}) => {

    // const active = "home"

    return (
            <>
<div className="avatar-container">
                        {/* <img src="https://avatars0.githubusercontent.com/u/35900628?v=4" alt="" className="rounded-full mx-auto absolute -top-20 w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110" /> */}
                        <Img  className="avatar-image" fixed={data.file.childImageSharp.fixed} />
                </div>
            </>
    )
}

export default Avatar