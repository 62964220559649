import './src/styles/global.css'
import './src/styles/custom.css'

// console.log("Gatsby browser")

import React from "react"
import Layout from "./src/components/layout"

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}


// import React from "react"
// import {AnimatePresence} from 'framer-motion';
// export const wrapPageElement = ({element}) => (
//     <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
//   );



