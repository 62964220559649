/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useState, useEffect, useRef } from 'react'

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

import { motion, AnimatePresence } from "framer-motion"

import Nav from './nav'
import Avatar from './avatar'



const duration = 0.5

const variants = {
  initial: {
    y: 0,
    x: 2000
  },
  animate: {
    // opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: duration,
      delay: 0,
      when: "beforeChildren",
    },
  },
  exit: () => {
    return {
      x: -2000,
      y: 0,
      transition: { duration: duration },
    }
  },
  enter: () => {
    return {
      x: 2000
    }
  }
}


const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "1024-LMT_0997.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`)


  const ref = useRef(null)
  const [height, setHeight] = useState(0)
  useEffect(() => {
    let newHeight = ref.current.clientHeight + 10;
    setHeight(newHeight)
    // if (newHeight > height) {setHeight(newHeight)}
  })


  return (
    <>
      <div className="container mx-auto my-32">
        <div>
          <div className="bg-white relative shadow rounded-lg w-5/6 md:w-5/6  lg:w-4/6 xl:w-3/6 mx-auto">
            <Avatar data={data} />
            <div className="">
              <Header />
              <Nav active={location.pathname} />
              <div
                style={{
                  height: height,
                  minHeight: "50vh",
                  overflow: "scroll",
                }}
              >
                <AnimatePresence initial={false}>
                  <motion.div className="bg-red-800"
                    style={{
                      position: "relative",
                      width: "100%",
                    }}
                    key={location.pathname}
                    variants={variants}
                    initial="initial"
                    enter="enter"
                    animate="animate"
                    exit="exit"
                  >
                    <div id={location.pathname} ref={ref} style={{
                      position: "absolute",
                      width: "100%"
                    }}>
                      {children}
                    </div>
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

